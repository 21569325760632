import React, { useState } from "react";
import Sidebar from "./Sidebar";
import InnerHead from "./InnerHead";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";
import Nav_bar from "./Nav_bar";
import { Link } from "react-router-dom";

function Marketplace() {
  const [value, setValue] = useState(75); // Change initial value to 75

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Nav_bar />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="row">
                <div className="col-lg-8">
                  <div className="mar_top_inner">
                    <span className="dash-head">Marketplace</span>
                    <div className="">
                      <ul class="nav nav-tabs mt-2">
                        <li>
                          <a data-toggle="tab" href="#All" className="active">
                            <button className="mark_tab_btn">
                              <div className="mark_tab_scnd">
                                <span className="plus_14_ff">All</span>
                              </div>
                            </button>
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#forsale">
                            <button className="mark_tab_btn">
                              <div className="mark_tab_scnd">
                                <img
                                  src={require("../images/MarketPlace/active_toggle.png")}
                                  alt="For Sale"
                                  className="mar_tab_img"
                                />
                                <span className="plus_14_ff">For Sale</span>
                              </div>
                            </button>
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#Upcoming">
                            <button className="mark_tab_btn">
                              <div className="mark_tab_scnd">
                                <img
                                  src={require("../images/MarketPlace/active_toggle.png")}
                                  alt="For Sale"
                                  className="mar_tab_img"
                                />
                                <span className="plus_14_ff">Upcoming</span>
                              </div>
                            </button>
                          </a>
                        </li>
                        <li className="mar_cls_none">
                          <a data-toggle="tab" href="#Closed">
                            <button className="mark_tab_btn">
                              <div className="mark_tab_scnd">
                                <img
                                  src={require("../images/MarketPlace/deactive_toggle.png")}
                                  alt="For Sale"
                                  className="mar_tab_img"
                                />
                                <span className="plus_14_ff">Closed</span>
                              </div>
                            </button>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-start align-items-end">
                  <img
                    src={require("../images/Landing/Market-top.png")}
                    alt="MArketPlace"
                    className="mark_top_img"
                  />
                </div>
              </div>
              <hr />
              <div>
                <div class="tab-content">
                  <div id="All" class="tab-pane fade in active show">
                    <div className="row my-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <Link to="/invest">
                                  <span className="hot_view">Invest</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/second.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <Link to="/investoriginal">
                                  <span className="hot_view">Invest</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/third.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <a href="/invest">
                                  {" "}
                                  <span className="hot_view">Invest</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/fourth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <a href="/invest">
                                  {" "}
                                  <span className="hot_view">Invest</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <a href="/invest">
                                  {" "}
                                  <span className="hot_view">Invest</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-scnd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-thrd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-frth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mark_pagi">
                      <div className="paginationcss">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"**"}
                          pageCount={2}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={0}
                          onPageChange={""}
                          containerClassName={
                            "pagination pagination-md justify-content-center "
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                  <div id="forsale" class="tab-pane fade">
                    <div className="row my-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/second.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/third.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/fourth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-scnd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-thrd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-frth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mark_pagi">
                      <div className="paginationcss">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"**"}
                          pageCount={2}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={0}
                          onPageChange={""}
                          containerClassName={
                            "pagination pagination-md justify-content-center "
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                  <div id="Upcoming" class="tab-pane fade">
                    <div className="row my-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/second.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/third.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/fourth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-scnd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-thrd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-frth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mark_pagi">
                      <div className="paginationcss">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"**"}
                          pageCount={2}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={0}
                          onPageChange={""}
                          containerClassName={
                            "pagination pagination-md justify-content-center "
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                  <div id="Closed" class="tab-pane fade">
                    <div className="row my-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/second.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/third.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/MarketPlace/fourth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Hot-frst.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-scnd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-thrd.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <Form.Range
                                  value="60"
                                  name="range"
                                  // onChange={}
                                  className="custom-slider"
                                />
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dash_card">
                          <img
                            src={require("../images/Landing/Dash-scnd-frth.png")}
                            alt="Marketplace Pics"
                            className="market_pics_img"
                          />
                          <div className="market_in_main">
                            <div className="ycho_inner">
                              <div className="market_in_fstln">
                                <span className="pls_16_dd">Beach House</span>
                                <div className="hot_loc">
                                  <i class="fa-solid fa-location-dot hot_lc_img"></i>
                                  <span className="pls_14_dd"> Las Vegas</span>
                                </div>
                              </div>
                              <div className="hotpic_bot_hd">
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Price</span>
                                  <span className="plus_12_ff">
                                    {" "}
                                    00.00/MATIC
                                  </span>
                                </div>
                                <div className="market_in_fstln">
                                  <span className="plus_14_ff">Return</span>
                                  <span className="dash_zero"> 0.0%</span>
                                </div>
                              </div>
                            </div>
                            <div className="market_in_fstln">
                              <div className="hotpic_bot_hd">
                                <div className="custom-slider-container">
                                  <Form.Range
                                    value="75"
                                    name="range"
                                    // onChange={}
                                    className="custom-slider"
                                  />
                                </div>
                                <span className="plus_12_b5">
                                  <span className="market_percen">60%</span>{" "}
                                  Invested
                                </span>
                              </div>
                              <div className="hot_view_mn">
                                <span className="hot_view">Invest</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mark_pagi">
                      <div className="paginationcss">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"**"}
                          pageCount={2}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={0}
                          onPageChange={""}
                          containerClassName={
                            "pagination pagination-md justify-content-center "
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marketplace;
