import React from "react";
import InnerHead from "./InnerHead";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import Nav_bar from "./Nav_bar";

function Transaction() {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Nav_bar/>
            </div>
            <div className="px-4 my-4 transaction_padding_top">
              <span className="dash-head">Transaction</span>
              <div class="table-responsive">
              <table className='trans-table my-4'>
              <thead className='trans-head'>
                    <th>SI.NO</th>
                    <th>Name</th>
                    <th>City</th>
                    <th>Ownership</th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                  </thead>
                  <tbody>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="plus_14_ff">01</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">Ocean View</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> Varkala City</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">40%</span>
                        </td>
                        <td>
                            <span className="plus_14_ff">0x063834efe214f4199b1ad7181...</span>
                        </td>
                        <td>
                            <span className="plus_14_ff"> 07/06/2024</span>
                        </td>
                    </tr>
                    <tr className="text-center">
                    <td colSpan="6">
                    <div className="paginationcss">
                              <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"**"}
                                  pageCount={2}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={0}
                                  onPageChange={""}
                                  containerClassName={"pagination pagination-md justify-content-center "}
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                              />
                          </div>
                    </td>
                    </tr>
                  </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transaction;
