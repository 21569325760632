import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import Marketplace from "./pages/Marketplace";
import Transaction from "./pages/Transaction";
import Profile from "./pages/Profile";
import Portfolio from "./pages/Portfolio";
import Invest from "./pages/Invest";
import InvestOriginal from "./pages/InvestOriginal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/invest" element={<Invest />} />
          <Route path="/investoriginal" element={<InvestOriginal />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
