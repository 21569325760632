import React from "react";
import InnerHead from "./InnerHead";
import Sidebar from "./Sidebar";
import ReactSwitch from "react-switch";
import Nav_bar from "./Nav_bar";

function Profile() {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Nav_bar />
            </div>
            <div className="px-4 my-4">
              <span className="dash-head">My Profile</span>
              <hr className="mt-4" />
              <div className="row profile_row my-4">
                <div className="col-lg-8">
                  <div className="prof_initial">
                    <div className="prof_top_main">
                      <div className="prof_in_top">
                        <div className="prof_main">
                          <div className="prof_prof_main">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="prof_prof_img"
                              viewBox="0 0 32 32"
                              id="profile"
                            >
                              <g fill="#5f5f5f" class="color000000 svgShape">
                                <circle
                                  cx="16"
                                  cy="6.96"
                                  r="6"
                                  fill="#5f5f5f"
                                  class="color000000 svgShape"
                                ></circle>
                                <path
                                  d="M30.86,26.84a15.07,15.07,0,0,0-4.11-7.47A12.47,12.47,0,0,0,25.13,18,15,15,0,0,0,16,15,15.24,15.24,0,0,0,5.24,19.37a15.07,15.07,0,0,0-4.11,7.47,3.42,3.42,0,0,0,.69,2.88A3.52,3.52,0,0,0,4.58,31H27.42a3.52,3.52,0,0,0,2.75-1.32A3.42,3.42,0,0,0,30.86,26.84Z"
                                  fill="#5f5f5f"
                                  class="color000000 svgShape"
                                ></path>
                              </g>
                            </svg>
                            <div className="prof_abs">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prof_cam"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                image-rendering="optimizeQuality"
                                shape-rendering="geometricPrecision"
                                text-rendering="geometricPrecision"
                                viewBox="0 0 2191.85 1913.6"
                                id="Camera"
                              >
                                <path
                                  fill-rule="nonzero"
                                  d="M1630.73 229.46l164.86 0c109.04,0 208.13,44.58 279.91,116.35 71.77,71.77 116.35,170.89 116.35,279.9l0 891.63c0,109.04 -44.57,208.13 -116.34,279.9 -71.77,71.78 -170.88,116.36 -279.92,116.36l-1399.34 0c-109.04,0 -208.13,-44.58 -279.9,-116.36 -71.78,-71.77 -116.36,-170.86 -116.36,-279.9l0 -891.63c0,-109.04 44.58,-208.14 116.36,-279.91 71.77,-71.78 170.86,-116.34 279.9,-116.34l164.87 0c15.58,-50.48 46.02,-94.89 83.46,-130.79 63.66,-61.08 151.05,-98.67 218.71,-98.67l465.25 0c67.66,0 155.06,37.59 218.71,98.67 37.43,35.9 67.87,80.31 83.47,130.79zm-534.81 359.12c133.33,0 254.1,54.07 341.49,141.46 87.4,87.4 141.47,208.16 141.47,341.5 0,133.33 -54.07,254.09 -141.47,341.49 -87.41,87.39 -208.14,141.46 -341.49,141.46 -133.35,0 -254.08,-54.07 -341.49,-141.46 -87.39,-87.41 -141.46,-208.15 -141.46,-341.49 0,-133.35 54.07,-254.09 141.46,-341.5 87.39,-87.39 208.16,-141.46 341.49,-141.46zm236.44 246.52c-60.49,-60.49 -144.09,-97.9 -236.44,-97.9 -92.34,0 -175.95,37.41 -236.44,97.9 -60.47,60.49 -97.9,144.1 -97.9,236.44 0,92.33 37.43,175.95 97.9,236.43 60.49,60.48 144.1,97.91 236.44,97.91 92.33,0 175.95,-37.43 236.44,-97.91 60.49,-60.48 97.9,-144.09 97.9,-236.43 0,-92.35 -37.41,-175.95 -97.9,-236.44zm463.23 -457.03l-227.87 0c-41.03,0 -74.3,-33.27 -74.3,-74.3 0,-36.68 -20.59,-71.15 -48.91,-98.3 -36.67,-35.19 -82.85,-56.85 -115.96,-56.85l-465.25 0c-33.11,0 -79.29,21.66 -115.96,56.85 -28.32,27.16 -48.91,61.62 -48.91,98.3 0,41.03 -33.27,74.3 -74.3,74.3l-227.87 0c-68.04,0 -129.95,27.89 -174.85,72.79 -44.9,44.91 -72.8,106.82 -72.8,174.86l0 891.63c0,68.02 27.9,129.95 72.8,174.85 44.91,44.9 106.82,72.8 174.85,72.8l1399.34 0c68.04,0 129.97,-27.9 174.86,-72.8 44.91,-44.91 72.79,-106.81 72.79,-174.85l0 -891.63c0,-68.02 -27.89,-129.95 -72.8,-174.84 -44.91,-44.91 -106.82,-72.81 -174.85,-72.81z"
                                  fill="#ffffff"
                                  class="color000000 svgShape"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="prof_sbhead">
                          <span className="prof_hed">XyzXyz</span>
                          <span className="prof_sbhed">
                            XyzXyz661@gmail.com
                          </span>
                        </div>
                      </div>
                      <div className="flex_input_posion">
                        <input
                          type="text"
                          placeholder="UID: 465651154"
                          className="prof_hd_input"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="prof_inp_img"
                          viewBox="0 0 16 16"
                          id="Copy"
                        >
                          <path
                            fill="#ffffff"
                            d="M4.00029246,4.08524952 L4,10.5 C4,11.8254834 5.03153594,12.9100387 6.33562431,12.9946823 L6.5,13 L10.9143985,13.000703 C10.7082819,13.5829319 10.1528467,14 9.5,14 L6,14 C4.34314575,14 3,12.6568542 3,11 L3,5.5 C3,4.84678131 3.41754351,4.29108512 4.00029246,4.08524952 Z M11.5,2 C12.3284271,2 13,2.67157288 13,3.5 L13,10.5 C13,11.3284271 12.3284271,12 11.5,12 L6.5,12 C5.67157288,12 5,11.3284271 5,10.5 L5,3.5 C5,2.67157288 5.67157288,2 6.5,2 L11.5,2 Z"
                            class="color212121 svgShape"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="prof_scnd_part">
                      <div className="prof_scprt_frst">
                        <span className="plus_14_ff">
                          Enable two-factor authentication for login
                        </span>
                        <div className="">
                          <ReactSwitch
                            checked={""}
                            onChange={""}
                            onColor="#fff"
                            offColor="#fff"
                            height={16}
                            width={34}
                            handleDiameter={10}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            className="custom-switch"
                          />
                        </div>
                      </div>
                      <div className="prof_scprt_scnd">
                        <div className="prof_cont">
                          <span className="lf14_nrml_ff">Contact Info</span>
                        </div>
                        <div className="prof_cont_det">
                          <div className="hotpic_bot_sbhd">
                            <div className="prof_con_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prfcon_user_icon"
                                enable-background="new 0 0 512 512"
                                viewBox="0 0 512 512"
                                id="user"
                              >
                                <circle
                                  cx="256.1"
                                  cy="128.6"
                                  r="128.6"
                                  fill="#ffffff"
                                  transform="rotate(-45.001 256.1 128.604)"
                                  class="color231f20 svgShape"
                                ></circle>
                                <path
                                  fill="#ffffff"
                                  d="M403.6,364.5c-9.9-9.9-63.1-61.1-147.5-61.1s-137.7,51.3-147.5,61.1C48.9,424.2,47.5,498.1,47.5,512h417.2
		C464.7,498.1,463.3,424.2,403.6,364.5z"
                                  class="color231f20 svgShape"
                                ></path>
                              </svg>
                              <span className="lf14_nrml_ff">First Name</span>
                            </div>
                            <span className="lf14_nrml_ff">XYX</span>
                          </div>
                          <hr className="my-4" />
                          <div className="hotpic_bot_sbhd">
                            <div className="prof_con_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prfcon_user_icon"
                                enable-background="new 0 0 512 512"
                                viewBox="0 0 512 512"
                                id="user"
                              >
                                <circle
                                  cx="256.1"
                                  cy="128.6"
                                  r="128.6"
                                  fill="#ffffff"
                                  transform="rotate(-45.001 256.1 128.604)"
                                  class="color231f20 svgShape"
                                ></circle>
                                <path
                                  fill="#ffffff"
                                  d="M403.6,364.5c-9.9-9.9-63.1-61.1-147.5-61.1s-137.7,51.3-147.5,61.1C48.9,424.2,47.5,498.1,47.5,512h417.2
		C464.7,498.1,463.3,424.2,403.6,364.5z"
                                  class="color231f20 svgShape"
                                ></path>
                              </svg>
                              <span className="lf14_nrml_ff">last Name</span>
                            </div>
                            <span className="lf14_nrml_ff">XYX</span>
                          </div>
                          <hr className="my-4" />
                          <div className="hotpic_bot_sbhd">
                            <div className="prof_con_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prfcon_user_icon"
                                viewBox="0 0 47.5 47.5"
                                id="Phone"
                              >
                                <defs>
                                  <clipPath id="a">
                                    <path
                                      d="M0 38h38V0H0v38Z"
                                      fill="#000000"
                                      class="color000000 svgShape"
                                    ></path>
                                  </clipPath>
                                </defs>
                                <g
                                  clip-path="url(#a)"
                                  transform="matrix(1.25 0 0 -1.25 0 47.5)"
                                  fill="#000000"
                                  class="color000000 svgShape"
                                >
                                  <path
                                    fill="#ffffff"
                                    d="m35.06 10.593-3.496 3.496a4.942 4.942 0 0 1-8.341-2.528c-5.765 1.078-11.372 6.662-11.721 11.653a4.918 4.918 0 0 1 2.587 1.36 4.944 4.944 0 0 1 0 6.991l-3.495 3.495a4.943 4.943 0 0 1-6.99 0C-6.881 24.574 24.575-6.882 35.06 3.604a4.942 4.942 0 0 1 0 6.989"
                                    class="color292f33 svgShape"
                                  ></path>
                                </g>
                              </svg>
                              <span className="lf14_nrml_ff">Phone Number</span>
                            </div>
                            <span className="lf14_nrml_ff">0123456789</span>
                          </div>
                          <hr className="my-4" />
                          <div className="hotpic_bot_sbhd">
                            <div className="prof_con_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prfcon_user_icon"
                                viewBox="0 0 100 100"
                                id="Email"
                              >
                                <path
                                  d="M34.05 50 9 66.31V33.69L34.05 50zm31.9 0L91 66.31V33.69L65.95 50zm-3.66 2.39-11.2 7.29c-.33.21-.71.32-1.09.32s-.76-.11-1.09-.32l-11.2-7.29L10.66 70l-1.57 1.02C9.58 73.84 12.04 76 15 76h70c2.96 0 5.42-2.15 5.91-4.98L89.33 70 62.29 52.39zM50 55.61 89.33 30l1.58-1.02C90.42 26.15 87.96 24 85 24H15c-2.96 0-5.42 2.16-5.91 4.98L10.66 30 50 55.61z"
                                  fill="#ffffff"
                                  class="color000000 svgShape"
                                ></path>
                              </svg>
                              <span className="lf14_nrml_ff">
                                {" "}
                                E-mail Address
                              </span>
                            </div>
                            <span className="lf14_nrml_ff">
                              {" "}
                              xyz661@gmail.com
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="prof_scprt_scnd">
                        <div className="prof_cont">
                          <span className="lf14_nrml_ff">Personal Info</span>
                        </div>
                        <div className="prof_cont_det">
                          <div className="hotpic_bot_sbhd">
                            <div className="prof_con_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prfcon_user_icon"
                                enable-background="new 0 0 512 512"
                                viewBox="0 0 512 512"
                                id="user"
                              >
                                <circle
                                  cx="256.1"
                                  cy="128.6"
                                  r="128.6"
                                  fill="#ffffff"
                                  transform="rotate(-45.001 256.1 128.604)"
                                  class="color231f20 svgShape"
                                ></circle>
                                <path
                                  fill="#ffffff"
                                  d="M403.6,364.5c-9.9-9.9-63.1-61.1-147.5-61.1s-137.7,51.3-147.5,61.1C48.9,424.2,47.5,498.1,47.5,512h417.2
		C464.7,498.1,463.3,424.2,403.6,364.5z"
                                  class="color231f20 svgShape"
                                ></path>
                              </svg>
                              <span className="lf14_nrml_ff">Legal Name</span>
                            </div>
                            <span className="lf14_nrml_ff">XYX</span>
                          </div>
                          <hr className="my-4" />
                          <div className="hotpic_bot_sbhd">
                            <div className="prof_con_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prfcon_user_icon"
                                viewBox="0 0 24 24"
                                id="Location"
                              >
                                <path
                                  d="M12,2a8.009,8.009,0,0,0-8,8c0,3.255,2.363,5.958,4.866,8.819,0.792,0.906,1.612,1.843,2.342,2.791a1,1,0,0,0,1.584,0c0.73-.948,1.55-1.885,2.342-2.791C17.637,15.958,20,13.255,20,10A8.009,8.009,0,0,0,12,2Zm0,11a3,3,0,1,1,3-3A3,3,0,0,1,12,13Z"
                                  fill="#ffffff"
                                  class="color000000 svgShape"
                                ></path>
                              </svg>
                              <span className="lf14_nrml_ff">Country</span>
                            </div>
                            <span className="lf14_nrml_ff">XYX</span>
                          </div>
                          <hr className="my-4" />
                          <div className="hotpic_bot_sbhd">
                            <div className="prof_con_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="prfcon_user_icon"
                                fill-rule="evenodd"
                                stroke-linejoin="round"
                                stroke-miterlimit="2"
                                clip-rule="evenodd"
                                viewBox="0 0 32 32"
                                id="Password"
                              >
                                <path
                                  d="M10,2c-3.311,0 -6,2.689 -6,6c0,3.311 2.689,6 6,6c2.219,0 4.157,-1.207 5.195,-3l1.805,0c0.155,0 0.308,-0.035 0.447,-0.105l1.553,-0.778l1.553,0.778c0.139,0.07 0.292,0.105 0.447,0.105l4,0c0.265,0 0.52,-0.105 0.707,-0.293l2,-2c0.391,-0.39 0.391,-1.024 0,-1.414l-2,-2c-0.187,-0.188 -0.442,-0.293 -0.707,-0.293l-9.805,-0c-1.037,-1.793 -2.976,-3 -5.195,-3Zm0,3c1.656,-0 3,1.344 3,3c0,1.656 -1.344,3 -3,3c-1.656,0 -3,-1.344 -3,-3c0,-1.656 1.344,-3 3,-3Zm0,2c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1Zm-4,9c-2.208,0 -4,1.792 -4,4l0,6c0,2.208 1.792,4 4,4l20,0c2.208,0 4,-1.792 4,-4l0,-6c0,-2.208 -1.792,-4 -4,-4l-20,0Zm1,4.586c0.256,-0 0.512,0.098 0.707,0.293l0.707,0.707l0.707,-0.707c0.39,-0.39 1.024,-0.39 1.414,-0c0.39,0.39 0.39,1.024 0,1.414l-0.707,0.707l0.707,0.707c0.39,0.39 0.39,1.024 0,1.414c-0.39,0.39 -1.024,0.39 -1.414,0l-0.707,-0.707l-0.707,0.707c-0.39,0.39 -1.024,0.39 -1.414,0c-0.39,-0.39 -0.39,-1.024 -0,-1.414l0.707,-0.707l-0.707,-0.707c-0.39,-0.39 -0.39,-1.024 -0,-1.414c0.195,-0.195 0.451,-0.293 0.707,-0.293Zm7.586,-0c0.256,-0 0.512,0.098 0.707,0.293l0.707,0.707l0.707,-0.707c0.39,-0.39 1.024,-0.39 1.414,-0c0.39,0.39 0.39,1.024 0,1.414l-0.707,0.707l0.707,0.707c0.39,0.39 0.39,1.024 0,1.414c-0.39,0.39 -1.024,0.39 -1.414,0l-0.707,-0.707l-0.707,0.707c-0.39,0.39 -1.024,0.39 -1.414,0c-0.39,-0.39 -0.39,-1.024 -0,-1.414l0.707,-0.707l-0.707,-0.707c-0.39,-0.39 -0.39,-1.024 -0,-1.414c0.195,-0.195 0.451,-0.293 0.707,-0.293Zm7.586,-0c0.256,-0 0.512,0.098 0.707,0.293l0.707,0.707l0.707,-0.707c0.39,-0.39 1.024,-0.39 1.414,-0c0.39,0.39 0.39,1.024 0,1.414l-0.707,0.707l0.707,0.707c0.39,0.39 0.39,1.024 0,1.414c-0.39,0.39 -1.024,0.39 -1.414,0l-0.707,-0.707l-0.707,0.707c-0.39,0.39 -1.024,0.39 -1.414,0c-0.39,-0.39 -0.39,-1.024 -0,-1.414l0.707,-0.707l-0.707,-0.707c-0.39,-0.39 -0.39,-1.024 -0,-1.414c0.195,-0.195 0.451,-0.293 0.707,-0.293Z"
                                  fill="#ffffff"
                                  class="color000000 svgShape"
                                ></path>
                              </svg>
                              <span className="lf14_nrml_ff">
                                Change Password
                              </span>
                            </div>
                            <span className="lf14_nrml_ff"> ****** </span>
                          </div>
                        </div>
                      </div>
                      <div className="prof_scprt_frst">
                        <span className="plus_14_ff">Logout</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="prfcon_user_icon"
                          viewBox="0 0 24 24"
                          id="logout"
                        >
                          <path
                            d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z"
                            fill="#ffffff"
                            class="color000000 svgShape"
                          ></path>
                          <path
                            d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z"
                            fill="#ffffff"
                            class="color000000 svgShape"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
