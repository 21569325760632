import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function Landing() {

  const navigate = useNavigate() ;

  const handlenavigate = async () => {
    const userAddress = localStorage.getItem("userAddress");
    if(userAddress) {
      navigate("/dashboard");
    } else {
      toast.error("Please Connect Your Wallet !");
    }
  }
  

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <section className="hero_section">
        <div className="container">
          <div className="row hero_main">
            <div className="col-lg-8 hero_left">
              <div className="hero_lft_top">
                <span className="hero_head">
                  Empower Your Investments with RWA Fractional Ownership
                </span>
                <div className="hero_subhead">
                  <span className="hero_sub_inner">
                    {" "}
                    Explore the world of real-world assets like never before.
                    Own a piece of high-value assets easily and securely through
                    fractional ownership.
                  </span>
                  <span className="hero_sub_inner">
                    {" "}
                    Tokenize Real-World Assets Seamlessly: Everything You Need,
                    One Platform.
                  </span>
                </div>
              </div>
              {/* <Link to="/dashboard"> */}
                <div className="hero_lft_bot" onClick={handlenavigate}>
                  <span className="hero_start"> Start Investing Now</span>
                </div>
              {/* </Link> */}
            </div>
            <div className="col-lg-4 hero_right">
              <img
                src={require("../images/Landing/hero-right.png")}
                alt="Golden Invest"
                className="hero_right_img"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="ychoose_section">
        <div className="container">
          <div className="ychoose_top">
            <span className="ychoose_head">
              Why Choose <br />
              AARKA Invest Tech!
            </span>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 ">
              <div className="ycho_lft_frst">
                <div className="ycho_inner">
                  <img
                    src={require("../images/Landing/Ychoose-frst.png")}
                    alt="No Initial"
                    className="ychoose_img"
                  />
                  <div className="ycho_inner">
                    <span className="ychose_in_hed"> No Initial Expenses</span>
                    <span className="ychose_in_sbhed">
                      {" "}
                      Say goodbye to hefty upfront costs, mortgage fees, and
                      maintenance expenses. Start investing with as little as
                      cryptocurrencies and reap the rewards effortlessly.
                    </span>
                  </div>
                </div>
              </div>
              <div className="ycho_lft_frst">
                <div className="ycho_inner">
                  <img
                    src={require("../images/Landing/Ychoose-scnd.png")}
                    alt="No Initial"
                    className="ychoose_img"
                  />
                  <div className="ycho_inner">
                    <span className="ychose_in_hed">Diversified Ownership</span>
                    <span className="ychose_in_sbhed">
                      {" "}
                      Simplify Investment in High-Value Assets: Fractional
                      Ownership Made Easy. Invest in Real Estate Units,
                      Diversify Your Portfolio, and Reap Benefits.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ycho_lft_scnd">
                <div className="ycho_inner">
                  <img
                    src={require("../images/Landing/Ychoose-thrd.png")}
                    alt="No Initial"
                    className="ychoose_img"
                  />
                  <div className="ycho_inner">
                    <span className="ychose_in_hed">
                      {" "}
                      Diversify Your Portfolio
                    </span>
                    <span className="ychose_in_sbhed">
                      {" "}
                      Explore the potential of real estate investments with the
                      flexibility of cryptocurrency. Begin your journey into
                      property investment today, starting with your preferred
                      crypto equivalent.
                    </span>
                  </div>
                </div>
              </div>
              <div className="ycho_lft_frst">
                <div className="ycho_inner">
                  <img
                    src={require("../images/Landing/Ychoose-frth.png")}
                    alt="No Initial"
                    className="ychoose_img"
                  />
                  <div className="ycho_inner">
                    <span className="ychose_in_hed"> Revenue</span>
                    <span className="ychose_in_sbhed">
                      {" "}
                      Generate income by investing your cryptocurrency in real
                      estate properties. Gain fractional ownership and enjoy
                      high returns through our efficient platform. Aarka Invest
                      Tech is a smart choice for diversifying your portfolio.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ycho_lft_frst">
                <div className="ycho_inner">
                  <img
                    src={require("../images/Landing/Ychoose-ffth.png")}
                    alt="No Initial"
                    className="ychoose_img"
                  />
                  <div className="ycho_inner">
                    <span className="ychose_in_hed">
                      {" "}
                      Effortless Administration
                    </span>
                    <span className="ychose_in_sbhed">
                      {" "}
                      Leave the day-to-day management to us. Our dedicated team
                      handles everything from property acquisition to tenant
                      management, ensuring your investment runs smoothly.
                    </span>
                  </div>
                </div>
              </div>
              <div className="ycho_lft_frst">
                <div className="ycho_inner">
                  <img
                    src={require("../images/Landing/Ychoose-sxth.png")}
                    alt="No Initial"
                    className="ychoose_img"
                  />
                  <div className="ycho_inner">
                    <span className="ychose_in_hed"> Top-Tier Security</span>
                    <span className="ychose_in_sbhed">
                      {" "}
                      Rest assured, your crypto ownership is securely locked in
                      our smart contract, ensuring unrivaled protection for your
                      property. No unauthorized access and no alterations to
                      ownership without your explicit consent.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hotpics_section">
        <div className="container">
          <div className="hotpic_main">
            <div className="hotpic_top">
              <span className="hero_sub_inner">Hot Picks</span>
              <span className="ychoose_head">
                Top Property Picks for Investment
              </span>
            </div>
            <div className="row hot_row my-4">
              <div className="col-lg-4 mt-2">
                <div className="hot_card">
                  <img
                    src={require("../images/Landing/Hot-frst.png")}
                    alt="Hot Pics"
                    className="Hot_pics_img"
                  />
                  <div className="hotpic_bot">
                    <div className="hotpic_bot_hd">
                      <span className="pls_16_dd">
                        {" "}
                        Luxurious Oceanfront Compound
                      </span>
                      <div className="hot_loc">
                        <i class="fa-solid fa-location-dot hot_lc_img"></i>
                        <span className="pls_14_dd"> Las Vegas</span>
                      </div>
                    </div>
                    <div className="hotpic_bot_sbhd">
                      <span className="pls_20_ff"> $ 13,55,000</span>
                      <div className="hot_view_mn">
                        <span className="hot_view">View</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-2">
                <div className="hot_card">
                  <img
                    src={require("../images/Landing/Hot-scnd.png")}
                    alt="Hot Pics"
                    className="Hot_pics_img"
                  />
                  <div className="hotpic_bot">
                    <div className="hotpic_bot_hd">
                      <span className="pls_16_dd">
                        {" "}
                        Luxurious Oceanfront Compound
                      </span>
                      <div className="hot_loc">
                        <i class="fa-solid fa-location-dot hot_lc_img"></i>
                        <span className="pls_14_dd"> Las Vegas</span>
                      </div>
                    </div>
                    <div className="hotpic_bot_sbhd">
                      <span className="pls_20_ff"> $ 13,55,000</span>
                      <div className="hot_view_mn">
                        <span className="hot_view">View</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-2">
                <div className="hot_card">
                  <img
                    src={require("../images/Landing/Hot-thrd.png")}
                    alt="Hot Pics"
                    className="Hot_pics_img"
                  />
                  <div className="hotpic_bot">
                    <div className="hotpic_bot_hd">
                      <span className="pls_16_dd">
                        {" "}
                        Luxurious Oceanfront Compound
                      </span>
                      <div className="hot_loc">
                        <i class="fa-solid fa-location-dot hot_lc_img"></i>
                        <span className="pls_14_dd"> Las Vegas</span>
                      </div>
                    </div>
                    <div className="hotpic_bot_sbhd">
                      <span className="pls_20_ff"> $ 13,55,000</span>
                      <div className="hot_view_mn">
                        <span className="hot_view">View</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hotpic_top">
              <div className="hot_mn_viw">
                <span className="con_lan_con">View More</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Prop_section">
        <div className="container">
          <div className="prop_top">
            <span className="ychoose_head">Properties</span>
            <span className="pls_20_dd">
              Explore a Variety of Properties Offering Fractional Ownership{" "}
              <br /> Opportunities and Potential for High Returns
            </span>
          </div>
          <div className="row my-5">
            <div className="col-lg-4">
              <div className="prop_card_frst">
                <img
                  src={require("../images/Landing/Prop-frst.png")}
                  alt="Properities"
                  className="prop_img"
                />
                <div className="prop_bot">
                  <span className="ychose_in_hed">Residential Properties</span>
                  <span className="prop_sub_inner">
                    {" "}
                    Residential real estate assets perform better in an economic
                    downturn. No matter what, housing is always in demand.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="prop_card_scnd">
                <img
                  src={require("../images/Landing/Prop-scnd.png")}
                  alt="Properities"
                  className="prop_img"
                />
                <div className="prop_bot">
                  <span className="ychose_in_hed">Apartments</span>
                  <span className="prop_sub_inner">
                    Condo investment has always been the safest way to get in on
                    the ground floor of premium residential real estate projects
                    by reputable property developers.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="prop_card_frst">
                <img
                  src={require("../images/Landing/Prop-thrd.png")}
                  alt="Properities"
                  className="prop_img"
                />
                <div className="prop_bot">
                  <span className="ychose_in_hed">Commercial Properties</span>
                  <span className="prop_sub_inner">
                    {" "}
                    Enjoy the same returns earned by investment funds, now you
                    have access to the same opportunities.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Landing;
