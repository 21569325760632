import React, { useEffect } from "react";
import useState from "react-usestateref";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BeatLoader } from 'react-spinners';
// import { WifiLoader } from "react-awesome-loaders";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";
import Web3 from "web3";
// import { toast } from "react-toastify";

const drawerWidth = 240;

function Header(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#121418", color: "#fff" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <div className="landing-header1">
          <img
            src={require("../images/Logo.png")}
            alt="logo image"
            className="logo-img"
          />
        </div>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/">
              <div className="land_header_signup">
                <span className="land_header_leftmenus">Products</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/">
              <div className="land_header_signup">
                <span className="land_header_leftmenus">Why Invest?</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/">
              <div className="land_header_signup">
                <span className="land_header_leftmenus">Litepaper</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/">
              <div
                className="lan_had_con"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="img-con"
                  viewBox="0 0 20 20"
                  id="wallet"
                >
                  <path d="M16 6H3.5v-.5l11-.88v.88H16V4c0-1.1-.891-1.872-1.979-1.717L3.98 3.717C2.891 3.873 2 4.9 2 6v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-1.5 7.006a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path>
                </svg>
                <span className="con_lan_con">Connect</span>
              </div>
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
      const storedAddress = localStorage.getItem('userAddress');
      if (storedAddress) {
        setAddress(storedAddress);
      }
    }, []);

    const { chainId, isConnected } = useWeb3ModalAccount();
  

  const projectId = "0b8444fa79de9a3821c5410564de263f";

  const mainnet = {
    chainId: 0x89,
    name: "Polygon Mainnet",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com/",
    rpcUrl: "https://polygon-rpc.com/",  


    // testnet
    // chainId: 0x61,
    // explorerUrl: "https://testnet.bscscan.com/",
    // rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // name: "Binance Smart Chain Testnet",
    // currency: "tBNB",
  };
  const chains = [mainnet];
  const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Polygon Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.mywebsite.com/"],
  };
  const ethersConfig = defaultConfig({
    metadata,
    chains,
    projectId,
  });
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
  });

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();

  const [address, setAddress, addressref] = useState("");
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);


  const getbalanceaddress = async () => {
    try {
      if (!isConnected) throw Error("User disconnected");

      //console.log("inside");

      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      setAddress(address);
      //console.log(address, "address");
      const balance = await provider.getBalance(address);
      localStorage.setItem("userAddress", address);
      const ethBalance = parseInt(balance) / 1000000000000000000;
      setBalance(ethBalance);
      localStorage.setItem("Matic Balance", ethBalance);
      // toast.success("Wallet Connected!");
      localStorage.setItem("wallettype", "wallet-connect");
      // setLoading(false);
    } catch (error) {
      console.error(error);
    }

};  

  if (isConnected) {
    const Useradd = localStorage.getItem("userAddress");
    if (Useradd == null) {
      getbalanceaddress();
    } else {
    }
  } else {
  }



  const handleWalletConnect = async () => {
    // handlepopClose();
    await open();
    // setLoading(true);

  };

  const disconnectWallet = async () =>{
    try {
      await disconnect();
      setAddress("");
      localStorage.clear();
      toast.success("Wallet Disconnected");
    } catch (error) {
      console.log(error,"error diccon");
    }
  }

  const [dropstatus, setdropstatus] = useState(false);

  const dropdowns = async () => {
    console.log(dropstatus,"dropstatus")
    if(dropstatus == true){
      setdropstatus(false);
    }else{
      setdropstatus(true);
    }
  };

  return (
    <div>
     <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
    {/* {loading == true ? (
      <>
        <div className=" container d-flex justity-content-center align-items-center">
          <BeatLoader loading={loading} size={15} color={"#f47521"} margin={2} />
        </div>
      </>
    ) : ( */}
      <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "block", lg: "none" } }}
          >
            <div className="drawer-head">
              <div className="landing-header1">
                <Link to="/">
                  <img
                    src={require("../images/Logo.png")}
                    alt="logo"
                    className="logo-img"
                  />
                </Link>
              </div>

              <div className="header-right-menu">
                <MenuIcon />
              </div>
            </div>
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", lg: "block" } }}
          >
            <div className="landing-header1">
              <Link to="/">
                <img
                  src={require("../images/Logo.png")}
                  alt="logo"
                  className="logo-img"
                />
              </Link>
            </div>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <>
              <div className="header_new_main">
                <div className="header-right-menu">
                  <Link to="/">
                    <div className="land_header_signup">
                      <span className="land_header_leftmenus">Products</span>
                    </div>
                  </Link>

                  <Link to="/">
                    <div className="land_header_signup">
                      <span className="land_header_leftmenus">Why Invest?</span>
                    </div>
                  </Link>
                  <Link to="/">
                    <div className="land_header_signup">
                      <span className="land_header_leftmenus">Litepaper</span>
                    </div>
                  </Link>
                </div>
                {addressref.current == "" ? (
                    <div
                    className="lan_had_con"
                    variant="outlined"
                    color="neutral"
                    onClick={() => handleWalletConnect()}
                  >
<svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="img-con"
                      viewBox="0 0 20 20"
                      id="wallet"
                    >
                      <path d="M16 6H3.5v-.5l11-.88v.88H16V4c0-1.1-.891-1.872-1.979-1.717L3.98 3.717C2.891 3.873 2 4.9 2 6v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-1.5 7.006a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path>
                    </svg>
                    <span className="con_lan_con">Connect</span>
                    
                  </div>
                ) : (
                  <div
                  className="lan_had_con"
                  variant="outlined"
                  color="neutral"
                  onClick={dropdowns}
                >
                 <span className="con_lan_con">{address.substring(0,10)}...</span>
                 <div className={ dropstatus == true ? "dropdown-content" : "dropdown-content d-none"} onClick={() => disconnectWallet()}>
                 <svg xmlns="http://www.w3.org/2000/svg" className="img-con" viewBox="0 0 100 100" id="Wallet"><path d="M93 48.627h-.5V34.968c0-4.263-3.157-7.792-7.254-8.398v-3.073c0-4.687-3.813-8.5-8.5-8.5H72.98l-1.983-5.285a1.5 1.5 0 0 0-1.864-.901l-19.201 6.186H10.735c-3.989 0-7.235 3.246-7.235 7.235V82.76c0 4.687 3.813 8.5 8.5 8.5h72c4.687 0 8.5-3.813 8.5-8.5V69.101h.5c1.93 0 3.5-1.57 3.5-3.5V52.127c0-1.929-1.57-3.5-3.5-3.5zM74.106 17.998h2.64c3.032 0 5.5 2.467 5.5 5.5v2.971h-4.961l-.299-.797-2.88-7.674zm-4.33-3 2.437 6.494 1.868 4.977H24.109l44.582-14.362 1.085 2.891zm-59.041 3h29.884l-18.84 6.07-7.453 2.401h-3.591c-2.335 0-4.235-1.9-4.235-4.235s1.9-4.236 4.235-4.236zM89.5 82.76c0 3.033-2.468 5.5-5.5 5.5H12a5.506 5.506 0 0 1-5.5-5.5V28.096c.021.016.046.026.068.042.262.185.535.354.821.504.053.028.109.052.163.079.265.131.538.246.82.344.048.017.094.036.142.052.312.101.633.177.962.235.073.013.147.023.221.034.34.049.685.083 1.038.083H84c3.032 0 5.5 2.467 5.5 5.5v13.659h-9.938c-4.687 0-8.5 3.813-8.5 8.5v3.474c0 4.687 3.813 8.5 8.5 8.5H89.5V82.76zm4-17.159a.5.5 0 0 1-.5.5H79.562a5.506 5.506 0 0 1-5.5-5.5v-3.474c0-3.033 2.468-5.5 5.5-5.5H93a.5.5 0 0 1 .5.5v13.474z" fill="#000000" class="color000000 svgShape"></path><path d="M83.449 54.522a4.347 4.347 0 0 0-4.343 4.342c0 2.395 1.948 4.342 4.343 4.342s4.342-1.948 4.342-4.342a4.347 4.347 0 0 0-4.342-4.342zm0 5.685c-.74 0-1.343-.602-1.343-1.342a1.343 1.343 0 0 1 2.685 0c0 .739-.602 1.342-1.342 1.342z" fill="#000000" class="color000000 svgShape"></path></svg>
                         <span className="">Disconnect</span>
                        </div>
                </div>
                ) }
              
              </div>
              {/*  */}
              {/* <React.Fragment>
                <Modal
                  aria-labelledby="modal-title"
                  aria-describedby="modal-desc"
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Sheet
                    variant="outlined"
                    xl={{
                      minWidth: 500,
                      borderRadius: "md",
                      p: 3,
                      boxShadow: "lg",
                    }}
                  >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                      component="h2"
                      id="modal-title"
                      level="h4"
                      textColor="inherit"
                      fontWeight="lg"
                      mb={1}
                    ></Typography>
                    <Typography id="modal-desc">
                      <h3 className="connect_a_connect_text">
                        Connect a Wallet
                      </h3>
                      <p className="connect_a_connect_text2">
                        No wallet extensions have been detected
                      </p>
                      <div className="meta_mask_btn">
                        <img src={require("../images/Landing/Metamask.png")} />
                        Meta Mask
                      </div>
                    </Typography>
                  </Sheet>
                </Modal>
              </React.Fragment> */}
              {/*  */}
            </>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>

    </div>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
