import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Sidebar_2 from "./Nav_bar";

function Dashboard() {

  const [balance , setBalance] = useState(0);

  useEffect(() => {
    const userBalance = localStorage.getItem("Matic Balance");
    if (userBalance) {
      setBalance(userBalance);
    }
  }, [])
  

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Sidebar_2 />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="ycho_inner my-4">
                <span className="dash-head">Dashboard</span>
                <span className="pls_16_dd"> Welcome To AARKA Invest Tech</span>
              </div>
              <div className="dash_frst">
                <div className="row dash_frst_inmain">
                  <div className="col-lg-3">
                    <div className="dash_frst_frst">
                      <div className="hero_subhead">
                        <span className="pls_16_dd"> Total Portfolio </span>
                        <span className="ychose_in_hed">$ 00.00</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dash_frst_scnd">
                      <div className="hero_subhead">
                        <span className="pls_16_dd"> Annual Income </span>
                        <span className="ychose_in_hed">$ 00.00</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dash_frst_thrd">
                      <div className="hero_subhead">
                        <span className="pls_16_dd"> Total Properties</span>
                        <span className="ychose_in_hed">$ 00.00</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dash_frst_frth">
                      <div className="hero_subhead">
                        <span className="pls_16_dd"> Wallet balance </span>
                        <span className="ychose_in_hed">$ {balance == "" ||
                          balance == null ||
                          balance == undefined
                            ? 0.0
                            : parseFloat(balance).toFixed(4)}
                        </span> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dash_scnd_part my-4">
                <div className="dash_align_left">
                  <p className="dash-head mb-4">My Properties</p>
                </div>
                <div className="row dash_frst_inmain">
                  <div className="col-lg-3">
                    <div className="dash_card">
                      <img
                        src={require("../images/Landing/Hot-frst.png")}
                        alt="Hot Pics"
                        className="dash_pics_img"
                      />
                      <div className="dashpic_bot">
                        <div className="hotpic_bot_hd">
                          <span className="pls_16_dd"> Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_sbhd">
                          <span className="pls_14_ff">
                            {" "}
                            $ 00.00<span className="plus_12_ff">/MATIC</span>
                            <span className="dash_zero ml-1">+0.0%</span>
                          </span>
                          <div className="hot_view_mn">
                            <span className="hot_view">View</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dash_card">
                      <img
                        src={require("../images/Landing/Dash-scnd-scnd.png")}
                        alt="Hot Pics"
                        className="dash_pics_img"
                      />
                      <div className="dashpic_bot">
                        <div className="hotpic_bot_hd">
                          <span className="pls_16_dd"> Boat House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_sbhd">
                          <span className="pls_14_ff">
                            {" "}
                            $ 00.00<span className="plus_12_ff">/MATIC</span>
                            <span className="dash_zero ml-1">+0.0%</span>
                          </span>
                          <div className="hot_view_mn">
                            <span className="hot_view">View</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dash_card">
                      <img
                        src={require("../images/Landing/Dash-scnd-thrd.png")}
                        alt="Hot Pics"
                        className="dash_pics_img"
                      />
                      <div className="dashpic_bot">
                        <div className="hotpic_bot_hd">
                          <span className="pls_16_dd"> Wooden Home</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_sbhd">
                          <span className="pls_14_ff">
                            {" "}
                            $ 00.00<span className="plus_12_ff">/MATIC</span>
                            <span className="dash_zero ml-1">+0.0%</span>
                          </span>
                          <div className="hot_view_mn">
                            <span className="hot_view">View</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    {" "}
                    <div className="dash_card">
                      <img
                        src={require("../images/Landing/Dash-scnd-frth.png")}
                        alt="Hot Pics"
                        className="dash_pics_img"
                      />
                      <div className="dashpic_bot">
                        <div className="hotpic_bot_hd">
                          <span className="pls_16_dd"> Single Resident</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_sbhd">
                          <span className="pls_14_ff">
                            {" "}
                            $ 00.00<span className="plus_12_ff">/MATIC</span>
                            <span className="dash_zero ml-1">+0.0%</span>
                          </span>
                          <div className="hot_view_mn">
                            <span className="hot_view">View</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
