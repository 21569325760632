import React from "react";
import Sidebar from "./Sidebar";
import InnerHead from "./InnerHead";
import GoogleMapReact from "google-map-react";
import Nav_bar from "./Nav_bar"

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Dashboard() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0 invest_padding_bottom">
            <div className="pos_sticky">
              <Nav_bar />
            </div>
            <div className="five_luxe transaction_padding_top">
              <div className="five_luxe_content">
                <h3>Five Luxe</h3>
                <p>upcoming</p>
              </div>
              <div className="starts_in">Starts in</div>
            </div>
            <div className="sqft_content">
              <div className="sqft_content_text">
                <p>3,153 sqft</p>
                <p>4 Bth</p>
                <p>4 Bed</p>
                <p className="border_none">JBR Dubai</p>
              </div>
              <div className="starts_timer">00h:00m:00m:00s</div>
            </div>
            <div className="d-flex display_flex">
              <div className="col-lg-8">
                <div className="five_luxe_banner">
                  <img
                    src={require("../images/Landing/five_luxe_banner.webp")}
                  />
                  <div className="listed_content">
                    <div className="listed_content_item">
                      <i class="ri-calendar-event-fill"></i>
                      <p>
                        31<span>Listed</span>
                      </p>
                    </div>
                    <div className="listed_content_item">
                      <i class="ri-eye-line"></i>
                      <p>
                        1253<span>Viewed</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 padding_inner">
                <div className="funding_statistics_box">
                  <h3>Funding Statistics</h3>
                  <div className="total_raise">
                    <p>Total Raise</p>
                    <h4>$00.00 / $00.00</h4>
                  </div>
                  <div className="border_funding"></div>
                  <div className="investors_content">
                    <div>
                      <p>20</p>
                      <h4>Investors</h4>
                    </div>
                    <div>
                      <p>$00.00 </p>
                      <h4>Available</h4>
                    </div>
                    <div className="border_none">
                      <p>40%</p>
                      <h4>Raised</h4>
                    </div>
                  </div>
                  <div className="annual_return_content">
                    <div>
                      <h4>Annual Return</h4>
                      <h4>0.0%</h4>
                    </div>
                    <div>
                      <p>Annual Rental Income</p>
                      <p>0.0%</p>
                    </div>
                  </div>
                  <div className="annual_return_content">
                    <div>
                      <h4>Annual Return</h4>
                      <h4>0.0%</h4>
                    </div>
                    <div>
                      <p>Annual Return</p>
                      <p>0.0%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex display_flex">
              <div className="col-lg-8 full_ounter_border">
                {/* Tab title */}
                <div className="">
                  <ul class="nav nav-tabs mt-2">
                    <li>
                      <a data-toggle="tab" href="#Details" className="active">
                        <button className="mark_tab_btn">
                          <div className="mark_tab_scnd">
                            <span className="plus_14_ff">Details</span>
                          </div>
                        </button>
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#forsale">
                        <button className="mark_tab_btn">
                          <div className="mark_tab_scnd">
                            <span className="plus_14_ff">Financials</span>
                          </div>
                        </button>
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#Documents">
                        <button className="mark_tab_btn">
                          <div className="mark_tab_scnd">
                            <span className="plus_14_ff">Documents</span>
                          </div>
                        </button>
                      </a>
                    </li>
                    <li className="mar_cls_none">
                      <a data-toggle="tab" href="#Strategy">
                        <button className="mark_tab_btn">
                          <div className="mark_tab_scnd">
                            <span className="plus_14_ff border_none">
                              Strategy
                            </span>
                          </div>
                        </button>
                      </a>
                    </li>
                  </ul>
                </div>
                {/*  */}

                {/* Tab Content */}
                <div class="tab-content">
                  {/*  */}
                  <div id="Details" class="tab-pane fade in active show row">
                    <div id="Detail1">
                      <div>
                        <div className="col-lg-12">
                          <div className="dubai_market_content">
                            <img
                              src={require("../images/Landing/dubai_market_icon.webp")}
                            />
                            <div className="">
                              <h4>Dubai Market</h4>
                              <p>
                                Dubai’s booming real estate market, known for
                                its luxurious lifestyle, iconic skyline, and
                                world-class infrastructure, attracts global
                                investors with high returns.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="dubai_market_content">
                            <img
                              src={require("../images/Landing/Guarantee_icon.webp")}
                            />
                            <div className="">
                              <h4>5-Year Rental Guarantee</h4>
                              <p>
                                The apartment generates steady monthly rental
                                payouts via a 5-year rental agreement with the
                                hotel, ensuring consistent income and a
                                projected annual ROI of around 9%.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="col-lg-12">
                          <div className="dubai_market_content">
                            <img
                              src={require("../images/Landing/new_development.webp")}
                            />
                            <div className="">
                              <h4>New Development</h4>
                              <p>
                                Five LUXE JBR, opened on March 31, 2024, offers
                                state-of-the-art facilities. The new facility
                                includes F&B of popular brands like Pacha.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="dubai_market_content Ultra">
                            <img
                              src={require("../images/Landing/ultra_icon.webp")}
                            />
                            <div className="">
                              <h4>Ultra Luxurious</h4>
                              <p>
                                Located in JBR, these four 1-bedroom apartments
                                offer stunning views, modern elegance, and
                                access to pools, gyms, and fine dining. Perfect
                                for residents and investors.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="details_border"></div>
                    <div className="details_description">
                      <h3>Description</h3>
                      <p>
                        is offering a luxurious one-bedroom apartment in the
                        newly opened Five Luxe JBR, a premier beachfront
                        <br />
                        development in the heart of Jumeirah Beach Residence
                        (JBR), Dubai. This investment opportunity combines
                        <br />
                        modern elegance with the convenience of a prime
                        location, offering breathtaking views of the popular JBR
                        <br />
                        Beach, Dubai Eye, and Palm Jumeirah.
                      </p>
                      <p>
                        What makes this property stand out is the guaranteed
                        5-year rental contract managed by Five Hotel,
                        <br /> ensuring consistent monthly rental payouts.
                        Investors can expect a projected ROI of around 10%,
                        providing
                        <br /> financial stability and peace of mind.
                      </p>
                      <p>
                        Residents will enjoy an array of world-class amenities,
                        including multiple swimming pools, a state-of-the-art
                        <br />
                        gym, the ReFIVE Spa, private beach access, and seven
                        extraordinary restaurants and nightclubs.
                      </p>
                      <p>
                        Located in one of Dubai’s most vibrant and sought-after
                        areas, this one-bedroom apartment offers a<br /> unique
                        blend of luxury and investment potential. With its prime
                        location, elegant design, and guaranteed
                        <br /> returns, Five Luxe JBR is a standout choice for
                        savvy investors looking to secure high returns and
                        long-term
                        <br /> value.
                      </p>
                    </div>
                    <div className="details_border"> </div>
                    <div
                      className="invest_map_location"
                      style={{ height: "32vh", width: "100%" }}
                    >
                      <h4>Location</h4>
                      <p>
                        <i class="ri-map-pin-2-fill"></i>
                        Dubai
                      </p>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                      >
                        <AnyReactComponent
                          lat={59.955413}
                          lng={30.337844}
                          text="My Marker"
                        />
                      </GoogleMapReact>
                    </div>
                  </div>
                  {/*  */}
                  <div id="Documents" class="tab-pane fade row">
                    <p className="text-white text-center">No Data</p>
                  </div>
                  {/*  */}
                  <div id="Strategy" class="tab-pane fade row">
                    <p className="text-white text-center">No Data</p>
                  </div>
                </div>
                {/*  */}
              </div>
              <div className="col-lg-4">
                <div className="col-lg-12">
                  <div className="funding_statistics_box investment">
                    <h3>Make an Investment</h3>
                    <div className="this_listing_content">
                      <p>This listing will open in</p>
                      <h4>00h:00m:00m:00s</h4>
                    </div>

                    <div className="wallet_balance_input">
                      <h4>Wallet Balance</h4>
                      <div className="wallet_balance_input_content">
                        <div>
                          <i class="ri-wallet-3-fill"></i>{" "}
                          <input type="number" placeholder="0"></input>
                        </div>

                        <button className="wallet_deposit_btn">Deposit</button>
                      </div>
                    </div>
                    <h4 className="estimated_earnings_title">
                      Estimated Earnings
                    </h4>
                    <div className="estimated_earnings_content">
                      <div className="projected_return">
                        <p>Projected Return</p>
                        <p>$0.00</p>
                      </div>
                      <div className="projected_return">
                        <p>Rental Income</p>
                        <p>$0.00</p>
                      </div>
                      <div className="projected_return">
                        <p>Appreciation</p>
                        <p>$0.00</p>
                      </div>
                    </div>
                    <button className="wallet_invest_button">Invest</button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="funding_statistics_box">
                    <h3>Timeline</h3>
                    <div className="timeline_outline_content">
                      <img
                        src={require("../images/Landing/timeline_ouutline.webp")}
                      />
                      <div>
                        <div className="start_listing">
                          <h4>Start Listing</h4>
                          <p>
                            {" "}
                            14.06.2024 - The start date of property
                            <br /> listing.
                          </p>
                        </div>
                        <div className="start_listing">
                          <h4>Transfer Title Deed</h4>
                          <p>
                            14.07.2024 - The date for the transfer of
                            <br /> property title deed.
                          </p>
                        </div>
                        <div className="start_listing">
                          <h4>Kick-off Property Rentals</h4>
                          <p>
                            15.07.2024 - The date for the property to
                            <br /> start generating rental income.
                          </p>
                        </div>
                        <div className="start_listing">
                          <h4>First Rental Payment</h4>
                          <p>
                            05.08.2024 - The date for the first rental
                            <br /> income distribution to all owners of the
                            <br /> property.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
