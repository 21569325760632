import React from "react";
import InnerHead from "./InnerHead";
import Sidebar from "./Sidebar";
import Form from "react-bootstrap/Form";
import Nav_bar from "./Nav_bar";
function Portfolio() {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Nav_bar />
            </div>
            <div className="px-4 my-4 transaction_padding_top">
              <span className="dash-head">Protfolio</span>
              <hr className="mt-4" />
              <div className="row my-4">
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/Landing/Hot-frst.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                        <div className="view_btn">View</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/MarketPlace/second.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                      </div>
                      <div className="view_btn">View</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/MarketPlace/third.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                      </div>
                      <div className="view_btn">View</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/MarketPlace/fourth.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                      </div>
                      <div className="view_btn">View</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/Landing/Hot-frst.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                      </div>
                      <div className="view_btn">View</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/Landing/Dash-scnd-scnd.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                      </div>
                      <div className="view_btn">View</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/Landing/Dash-scnd-thrd.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                      </div>
                      <div className="view_btn">View</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="dash_card">
                    <img
                      src={require("../images/Landing/Dash-scnd-frth.png")}
                      alt="Marketplace Pics"
                      className="market_pics_img"
                    />
                    <div className="market_in_main">
                      <div className="ycho_inner">
                        <div className="market_in_fstln">
                          <span className="pls_16_dd">Beach House</span>
                          <div className="hot_loc">
                            <i class="fa-solid fa-location-dot hot_lc_img"></i>
                            <span className="pls_14_dd"> Las Vegas</span>
                          </div>
                        </div>
                        <div className="hotpic_bot_hd">
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Price</span>
                            <span className="plus_12_ff"> 00.00/MATIC</span>
                          </div>
                          <div className="market_in_fstln">
                            <span className="plus_14_ff">Return</span>
                            <span className="dash_zero"> 0.0%</span>
                          </div>
                        </div>
                      </div>
                      <div className="view_btn">View</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
