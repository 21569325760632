import React from "react";

function Footer() {
  return (
    <div>
      <div className="footer_main">
        <div className="row foot_row">
          <div className="col-lg-4">
            <div className="hero_subhead">
              <img
                src={require("../images/Logo.png")}
                alt="Logo"
                className="foot_logo_img"
              />
              <span className="ychose_in_sbhed">
                {" "}
                Invest in high-value assets through fractional ownership.
                Secure, transparent, and accessible to all.
              </span>
            </div>
          </div>
          <div className="col-lg-2 mt-3">
            <div className="hero_subhead">
              <span className="plus_18_ff"> Quick links</span>
              <div className="ycho_inner">
                <span className="ychose_in_sbhed">Products</span>
                <span className="ychose_in_sbhed">Why Invest?</span>
                <span className="ychose_in_sbhed"> Light paper</span>
              </div>
            </div>
          </div>
          <div className="col-lg-2 mt-3">
            <div className="hero_subhead">
              <span className="plus_18_ff"> Socials</span>
              <div className="ycho_inner">
                <span className="ychose_in_sbhed">Twitter</span>
                <span className="ychose_in_sbhed">Facebook</span>
                <span className="ychose_in_sbhed">Linkedin</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <img
              src={require("../images/Landing/Footer.png")}
              alt="Asset"
              className="Foot_ass_img"
            />
          </div>
        </div>
        <hr />
        <div className="hotpic_top mt-3">
          <span className="pls_14_dd">
            Copyright by AARKA Invest Tech. All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
