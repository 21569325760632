const service = {
  signup: "users/register",
  emailotpverify: "users/emailotpverify",
  resendCode: "users/resendCode",
  signin: "users/login",
  logout: "users/logout",
  getContent: "adminapi/getContent",
  changepswdlink: "users/changepswdlink",
  verifyForgotpasslink: "users/verifyForgotpasslink",
  resetpassword: "users/forgotpassword",
  profileUpdate: "/user/profileAdd",
  update_profile: "users/update_profile",
  getuserProfile: "users/getUserProfile",
  getUserDetails: "users/getUserDetails",
  UpdateProfile: "users/UpdateProfile",

  getPropertyDetails: "admin/getPropertyDetails",

};

export default service;
